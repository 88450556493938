import { createSlice } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'utils/constants';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: null,
    subsCription: null,
    authToken: window.localStorage.getItem(AUTH_TOKEN),
    googlePermissionError: null,
    googleSignupError: null,
    googleSignInError: null
  },
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
      window.localStorage.setItem(AUTH_TOKEN, action.payload);
    },
    clearAuthToken: (state) => {
      window.localStorage.clear();
      state.authToken = null;
      state.currentUser = null;
    },
    setCurrentUser: (state, action) => {
      const { payload } = action;
      state.currentUser = payload;
    },
    setSubscription: (state, action) => {
      const { payload } = action;
      state.subsCription = payload;
    },
    setGooglePermissionError: (state, action) => {
      const { payload } = action;
      state.googlePermissionError = payload;
    },
    setGoogleSignupError: (state, action) => {
      const { payload } = action;
      state.googleSignupError = payload;
    },
    setGoogleSignInError: (state, action) => {
      const { payload } = action;
      state.googleSignInError = payload;
    }
  }
});

export const {
  setAuthToken,
  clearAuthToken,
  setCurrentUser,
  currentUser,
  setSubscription,
  subsCription,
  authToken,
  setGooglePermissionError,
  googlePermissionError,
  setGoogleSignupError,
  googleSignupError,
  googleSignInError,
  setGoogleSignInError
} = authSlice.actions;

export default authSlice.reducer;
