import { graphql } from 'babel-plugin-relay/macro';

export const GetUserSubscriptionQuery = graphql`
  query GetUserSubscriptionQuery {
    getUserSubscription {
      createdAt
      updatedAt
      subscriptionId
      customerId
      quantity
      unitAmount
      totalActiveInboxes
      downgradeQuantityDiff
      user {
        id
      }
      startDate
      expireDate
      status
    }
  }
`;
