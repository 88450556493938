import React from 'react';

function Loader() {
  return (
    // <div className="loader-container loader-container--bg">
    //   <div className="loader"></div>
    // </div>
    <div className="loader overflow-hidden">
      <span className="loader-ring"></span>
      <span className="loader-ring"></span>
    </div>
  );
}

export default Loader;
