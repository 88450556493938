/* eslint-disable no-undef */
const PROD = {
  BASE_URL: 'https://api.revli.com', // production api
  BASE_URL_FRONT_END_LANDING_PAGE: 'https://www.revli.com', //production landingPage app
  BASE_URL_FRONT_END_APP: 'https://app.revli.com' //production app
};

const DEV = {
  BASE_URL: 'https://ap1qa.revli.com',
  BASE_URL_FRONT_END_LANDING_PAGE: 'https://www.revli.com',
  BASE_URL_FRONT_END_APP: 'https://qa.revli.com'
};

const LOCAL = {
  BASE_URL: 'http://localhost:8001',
  BASE_URL_FRONT_END_LANDING_PAGE: 'http://localhost:3001',
  BASE_URL_FRONT_END_APP: 'http://localhost:3000'
};

const MAP = {
  production: PROD,
  development: DEV,
  local: LOCAL
};

let Config = MAP[process.env.REACT_APP_MODE];

export default Config;
