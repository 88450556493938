import { graphql } from 'babel-plugin-relay/macro';

export const meQuery = graphql`
  query meQuery {
    me {
      id
      firstName
      lastName
      profileUrl
      email
      isSocial
      isUserOnFreeTrial
      createdAt
      trialActiveTill
      isAdmin
      dashboardAccess
    }
  }
`;
