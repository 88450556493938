import AppProvider from 'store/store';
import AppLayout from 'appLayout';

function AppWrapper() {
  return (
    <AppProvider>
      <AppLayout />
    </AppProvider>
  );
}

export default AppWrapper;
