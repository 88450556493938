import graphql from 'babel-plugin-relay/macro';

export const GenerateAccountInitUriMutation = graphql`
  mutation GenerateAccountInitUriMutation($input: GenerateAuthAccountInitUriInput!) {
    generateAuthInitUri(input: $input) {
      uri
      success
    }
  }
`;
