/**
 * @generated SignedSource<<89600811a8ab5f510b7601bf908159e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateAuthAccountInitUriPayload",
    "kind": "LinkedField",
    "name": "generateAuthInitUri",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uri",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateAccountInitUriMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateAccountInitUriMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9e345d8d80f4e4fe118d2246fe144143",
    "id": null,
    "metadata": {},
    "name": "GenerateAccountInitUriMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateAccountInitUriMutation(\n  $input: GenerateAuthAccountInitUriInput!\n) {\n  generateAuthInitUri(input: $input) {\n    uri\n    success\n  }\n}\n"
  }
};
})();

node.hash = "09e5f43945987cf40edfbb186d166bff";

module.exports = node;
