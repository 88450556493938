/**
 * @generated SignedSource<<b78ea24837744406bb015ea6bd438eb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LinkEmailSpamTestResultMutationPayload",
    "kind": "LinkedField",
    "name": "linkEmailSpamTestResult",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "response",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkEmailSpamTestResultMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LinkEmailSpamTestResultMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1628f3412c5cae4fa2e88ea72354b2b8",
    "id": null,
    "metadata": {},
    "name": "LinkEmailSpamTestResultMutation",
    "operationKind": "mutation",
    "text": "mutation LinkEmailSpamTestResultMutation(\n  $input: LinkEmailSpamTestResultMutationInput!\n) {\n  linkEmailSpamTestResult(input: $input) {\n    response\n  }\n}\n"
  }
};
})();

node.hash = "fd43784ce7d8f0f4a54dc24e9e5af5cd";

module.exports = node;
