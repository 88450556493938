import React, { lazy, Suspense, useEffect } from 'react';
import { fetchQuery } from 'relay';
import { meQuery } from 'services/queries/meQuery';
// import { GetUserSubscriptionQuery } from 'services/queries/GetUserSubscriptionQuery';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Loader from 'components/Loader/Loader';
// eslint-disable-next-line prettier/prettier
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setCurrentUser } from 'slices/authSlice';
import Header from 'components/Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { update as updateIntercom } from 'components/Intercom';
import { createBrowserHistory } from 'history';
const VerifyEmail = lazy(() => import('components/VerifyEmail/VerifyEmail'));
const ResetPasswordLink = lazy(() => import('components/ResetPasswordLink/ResetPasswordLink'));
const AllRoutes = lazy(() => import('routes'));
import { load as loadIntercom, boot as bootIntercom } from 'components/Intercom';

Sentry.init({
  dsn: 'https://0d44c6a8d5d04d66ae34ae880c447c1d@o1296804.ingest.sentry.io/6524164',
  // tracesSampleRate: 1.0,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ]
});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppLayout = () => {
  const authToken = useSelector((state) => state.auth.authToken);
  const history = createBrowserHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      fetchQuery(meQuery, {})
        .toPromise()
        .then((data) => {
          dispatch(setCurrentUser(data.me));
          loadIntercom();
          bootIntercom({
            name: `${data?.me?.firstName} ${data?.me?.lastName}`,
            email: data?.me?.email,
            user_id: data?.me?.id
          });
          // show();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  useEffect(() => {
    history.listen((location) => {
      // console.log('update');
      // Calls Intercom('update') on every page change
      updateIntercom();
    });
  }, [history]);

  return (
    <BrowserRouter>
      <main className={authToken ? 'page-wrapper' : ''}>
        <Suspense fallback={<Loader />}>
          <ToastContainer limit={1} />
          {authToken && <Header />}
          <SentryRoutes>
            <Route path={'verify/:id/:token'} element={<VerifyEmail />} />
            <Route path={'reset-password/:id/:token'} element={<ResetPasswordLink />} />
            <Route path="/*" element={<AllRoutes />} />
          </SentryRoutes>
        </Suspense>
      </main>
    </BrowserRouter>
  );
};

export default AppLayout;
