import graphql from 'babel-plugin-relay/macro';

export const ConnectAuthUserInboxMutation = graphql`
  mutation ConnectAuthUserInboxMutation($input: ConnectAuthAccountUserInboxInput!) {
    connectAuthUserInbox(input: $input) {
      connectionResponse {
        success
        userInboxId
      }
    }
  }
`;
