import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import appReducer from '../slices/appSlice';
import authReducer from '../slices/authSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer
  }
});

export default function AppProvider({ children }) {
  return <Provider store={store}>{children}</Provider>;
}
